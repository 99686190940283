<template>
  <div>
    <div class="hea">
      <div class="hea1">
        <img
          @click="goindex"
          class="heaimg"
          src="../../assets/hui3.png"
          alt=""
        />
        <p class="heap">我要购买</p>
      </div>
      <div class="hea2">
        <img class="hea2img" src="../../assets/header.png" alt="" />
        <div class="hea3">
          <p>用户名：{{ userInfo.username }}</p>
          <p
            class="hea3p"
            v-if="goodsDetail.flag == 0 && goodsDetail.expirationTime != null"
          >
            VIP用户
          </p>
          <p class="hea3p" v-else>您还没有开通服务</p>
        </div>
      </div>
      <div @click="toOrder" class="heagou">
        购买记录
      </div>
    </div>
    <div class="con">
      <p class="con1">开通会员畅享更多高级功能</p>
      <div class="coner">
        <div
          class="coner1"
          @click="geter(item, index)"
          v-for="(item, index) in goodsDetail.goodsSpecificationList"
          :key="index"
          :class="{ actconer1: erId == index }"
        >
          <p class="er1">{{ item.name }}</p>
          <p class="er2"><span>￥ </span>{{ item.amount / 100 }}</p>
          <!-- <p
            class="er3"
            v-show="appCode == 'taxlaw'"
            v-if="item.id == 'zhongshenghuiyuan'"
          >
            ￥ <s>398</s>
          </p>
          <p class="er3" v-show="appCode == 'taxlaw'" v-else>￥ <s>298</s></p>
          <p
            class="er4"
            v-show="appCode == 'taxlaw'"
            v-if="item.id == 'zhongshenghuiyuan'"
          >
            0.01元/天
          </p>
          <p class="er4" v-show="appCode == 'taxlaw'" v-else>1.46元/天</p> -->
          <!-- <div class="posi" v-if="index == 0">
            最多人购买
          </div> -->
        </div>
      </div>
      <div class="foot" @click="toBay">
        <!-- <img class="f1" src="../../assets/memai/f.png" alt="" /> -->
        <p class="f2">点击此处立即支付</p>
      </div>
      <p class="con4">会员权益</p>
      <div class="con5">
        <div class="con6" v-for="(item, index) in silist" :key="index">
          <!-- <img class="c6" :src="item.image" alt="" /> -->
          <p v-if="index != 2" class="c7">{{ item.name }}</p>
          <p v-else class="c7">
            {{ erId == 0 ? '3.一年服务期限' : '3.永久服务期限' }}
          </p>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="dialog">
      <div v-show="isShow" class="tips">请仔细阅读并确认服务协议</div>
      <div v-show="sizeShow" class="tips">请选择会员套餐</div>
      <div v-show="buyShow" class="tips">会员套餐购买成功</div>
    </div>
    <van-overlay :show="showAgree" @click="showAgree = false">
      <div class="wrapper" @click="showAgree = false">
        <div class="block">
          <div v-html="detail.agreementName" class="dialogTop"></div>
          <div
            v-html="detail.agreementContent"
            style="
              text-indent: 25px;
              overflow-y: auto;
              height: 370px;
              text-align: justify;
              overflow: auto;
              padding: 0 5px 0 5px;
            "
          ></div>
          <div class="offSumbit" @click="showAgree = false">关闭</div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block1">
          <div class="dialogTop">账号绑定</div>
          <div class="dialogPhone">
            <img
              src="../../assets/icon-6.png"
              style="float: left; margin-right: 10px; width: 20px"
            />
            <van-field
              v-model="phone"
              placeholder="手机号"
              type="number"
            ></van-field>
          </div>
          <div class="dialogCode">
            <img
              src="../../assets/icon-7.png"
              style="float: left; margin-right: 10px; width: 20px"
            />
            <van-field
              v-model="code"
              placeholder="验证码"
              type="number"
            ></van-field>
            <span
              v-show="sendAuthCode"
              style="font-size: 9px; color: #378bf4"
              @click="getCode"
              >获取验证码</span
            >
            <span v-show="!sendAuthCode" style="font-size: 9px; color: #777777"
              >已发送({{ auth_time }})</span
            >
          </div>
          <div class="dialogSumbit" @click="dialogSumbit">确定</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import im1 from '../../assets/memai/11.png'
import im2 from '../../assets/memai/22.png'
import im3 from '../../assets/memai/33.png'
import im4 from '../../assets/memai/44.png'
let vm = null
import {
  goodsDetail, //商品详情
  detail, //应用应用
  getUserInfo, //用户信息
  buy,
  // // pay,
  sendCode,
  bindMoibleByCode,
} from '@/api/member'

import { Toast } from 'vant'
export default {
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'divport',
        content:
          'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
      },
    ],
  },
  data() {
    return {
      erId: 0,
      silist: [
        { name: '1.无限次查询权限', image: im1 },
        { name: '2.五大数据库的持续更新', image: im2 },
        { name: '3.永久服务期限', image: im3 },
        { name: '4.手机/电脑双端查询', image: im4 },
      ],
      /*  */
      lb: true,
      aaa: 0,
      orderId: '', //订单id
      appId: '',
      appCode: '',
      goodsCode: '',
      radio: true,
      detail: {},
      goodsDetail: [],
      userInfo: {},
      isShow: false,
      show: false,
      showAgree: false,
      sizeShow: false,
      buyShow: false,
      phone: '',
      code: '',
      amount: 0, //商品价格
      goodSize: '', //选择的商品型号
      status: '', //订单状态
      sendAuthCode: true, //验证码状态
      auth_time: 0,
      windowHeight: document.documentElement.clientHeight + 'px',
      maxX: document.documentElement.clientWidth - 50,
      maxY: document.documentElement.clientHeight - 80,
      redirectUrl: '',
      minX: 0,
      minY: 0,
      x: 100,
      y: 100,
      isOpen: false,
      successGoBack: '',
      routerType: '',
    }
  },
  created: function() {
    this.x = this.maxX
    this.y = this.maxY - 50
    this.redirectUrl = this.$route.query.redirectUrl
    this.successGoBack = this.$route.query.successGoBack
    vm = this
    // vm.appId = vm.$route.query.id; //应用ID
    vm.appCode = vm.$route.query.appCode //应用code
    vm.goodsCode = vm.$route.query.goodsCode //应用code
    // 随便查（taxlaw）传来的参数 传递给goods

    vm.getIsLogin()
    // this.getData(this.appId)
    if (this.$route.query.appCode == 'Ssc') {
      this.routerType = 'ssc'

      this.silist[3].name = '关键词全库一键搜索'
    }
  },
  beforeCreate() {
    if (this.$route.query.appCode == 'taxlaw') {
      document.title = '税税查-中国最全的税法库'
    }
  },
  methods: {
    geter(item, index) {
      this.erId = index
      window.console.log(item)
      this.goodSize = item.id
      this.amount = item.amount
    },
    goindex() {
      this.$router.go(-1)
    },
    //登陆
    getIsLogin() {
      getUserInfo().then((res) => {
        if (res.code === 401) {
          // let url = "/api/snslogin/wxgzh?redirect_uri=" + encodeURIComponent(location.href);
          window.console.log(
            '/api/snslogin/wxgzh?redirect_uri=' +
              encodeURIComponent(encodeURIComponent(location.href))
          )
          // window.location.replace(url);
          window.location.href =
            '/api/snslogin/wxgzh?redirect_uri=' +
            encodeURIComponent(encodeURIComponent(location.href))
        }
        this.userInfo = res.data
      })
      // this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 500,
        mask: true,
      })
      this.getData(this.appCode, this.goodsCode) //商品信息
    },
    //返回上一页
    back() {
      this.$router.go(-1)
    },
    //获取商品
    getData(appCode, goodsCode) {
      window.console.log(appCode, goodsCode)
      let that = this
      let data = { code: goodsCode, random: Math.random() } //商品
      goodsDetail(data).then((res) => {
        that.goodsDetail = res.data
        if (
          that.goodsDetail.goodsSpecificationList == '' ||
          that.goodsDetail.goodsSpecificationList.length == 0
        ) {
          that.amount = that.goodsDetail.amount
          that.goodSize = that.goodsDetail.code
          that.goodsDetail.goodsSpecificationList = [res.data]
        } else {
          for (
            let i = 0;
            i < that.goodsDetail.goodsSpecificationList.length;
            i++
          ) {
            if (
              !(
                that.goodsDetail.isVip != -1 &&
                that.goodsDetail.goodsSpecificationList[i].type == 1
              )
            ) {
              that.amount = that.goodsDetail.goodsSpecificationList[i].amount
              that.goodSize = that.goodsDetail.goodsSpecificationList[i].id
              window.console.log(that.goodSize)
              return
            }
          }
        }
        window.console.log('商品数据：' + that.goodsDetail.name)
        // setTimeout(function() {
        //   that.lb = false;
        // }, 500);
      })
      detail({ code: appCode, random: Math.random() }).then((res) => {
        that.detail = res.data
        window.console.log('商品协议：' + res.data.name)
      }) //协议
    },
    //选择的会员型号
    selectSize(item) {
      window.console.log(item)
      this.goodSize = item.id
      this.amount = item.amount
    },
    //查看协议
    agreeChange() {
      sessionStorage.setItem('name', this.detail.agreementName)
      sessionStorage.setItem('content', this.detail.agreementContent)
      this.$router.push({
        path: '/agreement',
        query: { title: this.detail.name },
      })
      this.showAgree = true
    },
    //获取验证码
    getCode() {
      let that = this
      if (that.phone === '') {
        Toast('请输入手机号')
      } else {
        sendCode({ mobile: that.phone, codeType: 1 }).then((res) => {
          window.console.log(res)
          this.sendAuthCode = false
          this.auth_time = 60
          var auth_timetimer = setInterval(() => {
            this.auth_time--
            if (this.auth_time <= 0) {
              this.sendAuthCode = true
              clearInterval(auth_timetimer)
            }
          }, 1000)
        })
      }
    },
    //支付
    toBay() {
      window.console.log(this.goodSize, this.goodsCode, 'pppppppppp')
      let that = this
      if (that.radio === false) {
        that.isShow = true
        setTimeout(function() {
          that.isShow = false
        }, '1000')
      } else {
        that.isShow = false //未选择协议的弹窗
        window.console.log(that.userInfo.mobile)
        if (that.userInfo.mobile === undefined) {
          that.show = true //未绑定手机号的弹窗
        } else if (that.userInfo.mobile === '') {
          that.show = true //未绑定手机号的弹窗
        } else {
          if (that.goodSize == '') {
            that.sizeShow = true //未选择会员型号
          } else {
            let data = {
              code: that.goodsCode,
              goodsSpecificationId: that.goodSize,
            }
            buy(data).then((res) => {
              that.orderId = res.data.id
              if (res.data.status == 1) {
                this.getData(this.appCode, this.goodsCode) //商品信息
                that.buyShow = true
                setTimeout(function() {
                  that.buyShow = false
                }, '1000')
                if (this.redirectUrl) {
                  window.location.href = that.redirectUrl
                }
              } else {
                if (this.redirectUrl) {
                  window.location.href =
                    '/api/order/pay/' +
                    that.orderId +
                    '?successUrl=' +
                    encodeURIComponent(this.redirectUrl)
                } else if (this.successGoBack) {
                  window.location.href =
                    '/api/order/pay/' +
                    that.orderId +
                    '?successGoBack=' +
                    this.successGoBack
                } else {
                  window.location.href = '/api/order/pay/' + that.orderId
                }
              }
            })
          }
        }
      }
    },
    //跳转购买记录
    toOrder() {
      let that = this
      if (that.userInfo.mobile === undefined) {
        that.show = true //未绑定手机号的弹窗
      } else if (that.userInfo.mobile === '') {
        that.show = true //未绑定手机号的弹窗
      } else {
        var data = {
          appId: this.appId,
          appCode: this.appCode,
        }
        this.$router.push({ path: '/order', query: data })
      }
    },
    //跳转会员激活页面
    toActivation() {
      this.$router.push({ path: '/activation' })
    },
    //账号绑定提交
    dialogSumbit() {
      let that = this
      let obj = {
        mobile: that.phone,
        verifyCode: that.code,
        bindType: 1,
      }
      bindMoibleByCode(obj).then((res) => {
        window.console.log(res)
        Toast(res.msg)
        that.show = false
        that.getIsLogin()
      })
    },
    //返回按钮开关
    openButton() {
      if (this.isOpen) {
        this.x = this.maxX
        this.isOpen = false
      } else {
        this.x = this.maxX - 90
        this.isOpen = true
      }
    },
    //点击返回相关app
    backButton() {
      window.console.log(this.redirectUrl)
      window.location.href = this.redirectUrl
    },
    //图标拖动事件
    moveing(e) {
      e.preventDefault()
      let y = e.changedTouches[0].clientY - 31 //减去高度的一半， 使手指与图标居中
      if (this.minY < y && y < this.maxY) {
        this.y = y
      } else if (y < this.minY) {
        this.y = 0
      } else if (y > this.maxY) {
        this.y = this.maxY
      }
    },
  },
}
</script>

<style scoped>
.hea {
  width: 100%;
  height: 132px;
  background: #2670e2;
  /* position: relative; */
}
.hea1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.heap {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.heaimg {
  width: 40px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}
.hea2 {
  display: flex;
  align-items: center;
}
.hea2img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 0 16px;
}
.hea3 p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.hea3p {
  padding-top: 5px;
}
.heagou {
  width: 72px;
  height: 28px;
  background: #ffffff;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2670e2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  position: absolute;
  right: 0;
  top: 68px;
}
.con {
  width: 100%;
  background: #fff;
  border-radius: 10px 10px 0 0;
  margin-top: -30px;
}
.con1 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-left: 15px;
  padding-top: 12px;
}
.coner {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
}
.coner1 {
  margin-left: 15px;
  width: 150px;
  height: 170px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #bad5ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
}
.er1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-top: 10px;
  padding: 0 10px;
}
.er2 {
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2670e2;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-top: 4px;
}
.er2 span {
  font-size: 18px;
}
.er3 {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-top: 6px;
}
.er4 {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-top: 9px;
}
.actconer1 {
  background: linear-gradient(180deg, #ffffff 0%, #d7e7ff 100%);
  border-radius: 8px;
  border: 2px solid #2670e2;
}
.con4 {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin: 24px 0 16px 15px;
}
.con5 {
  width: calc(100% - 30px);
  margin-left: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.con6 {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  /* background: linear-gradient(225deg, #e7f0ff 0%, #e3eeff 100%); */
  border-radius: 6px;
}
.c6 {
  width: 40px;
  height: 40px;
  margin-left: 16px;
  margin-top: 20px;
}
.c7 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-left: 16px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.foot {
  width: calc(100% - 30px);

  height: 50px;
  background: #2670e2;
  border-radius: 4px;
  margin: 20px 0;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.f1 {
  width: 70%;

  height: 30px;
}
.f2 {
  position: absolute;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.posi {
  width: 88px;
  height: 26px;
  background: #2670e2;
  border-radius: 15px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -13px;
}

/*  */
.dialog {
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}
.tips {
  background-color: #999999;
  color: #ffffff;
  font-size: 12px;
  width: 165px;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80%;
  height: 450px;
  background-color: #fff;
  border-radius: 10px;
}

.block >>> p {
  height: 370px;
  overflow-y: auto;
  text-align: justify;
}

.block1 {
  width: 80%;
  height: 250px;
  background-color: #fff;
  border-radius: 10px;
}

.offSumbit {
  margin: 5px auto;
  line-height: 30px;
  background-color: #378bf4;
  color: white;
  width: 70px;
  font-size: 12px;
  text-align: center;
  border-radius: 10px;
}

.dialogTop {
  width: 100%;
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background-color: #378bf4;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.dialogPhone {
  margin: 25px 25px 25px 25px;
  width: 80%;
  height: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f5fa;
}

.dialogPhone >>> .van-cell {
  width: 70%;
  float: left;
  padding: 0;
  font-size: 11px;
  color: #666666;
}

.dialogCode {
  margin: 25px 25px 25px 25px;
  width: 80%;
  height: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f5fa;
}

.dialogCode >>> .van-cell {
  width: 50%;
  float: left;
  padding: 0;
  font-size: 11px;
  color: #666666;
}

.dialogSumbit {
  width: 80%;
  font-size: 12px;
  color: #ffffff;
  line-height: 30px;
  background-color: #378bf4;
  margin: 0 auto;
  text-align: center;
  border-radius: 20px;
}

.van-cell:not(:last-child)::after {
  border-bottom: 0;
}
.backButton {
  position: fixed;
  width: 150px;
  height: 62px;
  background: url('../../assets/return.png');
  background-size: contain;
  z-index: 999;
  transition: left 1.5s;
}
.backButton > img {
  position: relative;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
}
</style>
